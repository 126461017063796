<script setup>
const props = defineProps({
    autoInit: {
        type: Boolean,
        default: true,
    },
    offset: {
        type: [Array, String],
        default: () => '0 0',
    },
});

const { public: config } = useRuntimeConfig();

const { clientId, token, widgetId } = config.helpspace;
const canInit = computed(() => {
    if (!import.meta.client) {
        return false;
    }

    return !!(clientId && token && widgetId);
});

const calcOffsetValues = () => {
    let offset = props.offset;

    // convert string to array
    if (typeof offset === 'string') {
        offset = offset.split(' ').map((value) => parseInt(value, 10));
    }

    const values = {
        top: offset[0] ?? 0,
        right: offset[1] ?? 0,
        bottom: offset[2] ?? 0,
        left: offset[3] ?? 0,
    };

    if (offset.length === 1) {
        values.right = values.left = values.bottom = values.top;
    }

    if (offset.length === 2) {
        values.bottom = values.top;
        values.left = values.right;
    }

    return values;
};

const offsetValues = calcOffsetValues();

const { proxy, load } = useScript(
    {
        src: 'https://cdn.helpspace.com/widget/widget-v1.js',
        'data-token': token,
        'data-client-id': clientId,
        'data-widget-id': widgetId,
        'data-auto-init': props.autoInit,
        'data-offset-top': offsetValues.top,
        'data-offset-right': offsetValues.right,
        'data-offset-bottom': offsetValues.bottom,
        'data-offset-left': offsetValues.left,
        tagPosition: 'bodyClose',
        defer: true,
        id: 'helpspace-widget-script',
        use() {
            return { HelpWidget: window.HelpWidget };
        },
    },
    { trigger: 'manual' }
);

// load widget
if (canInit.value) {
    load();
}
// init widget
proxy('init');

// destroy widget on unmount
onBeforeUnmount(() => {
    proxy('destroy');
});
</script>

<template>
    <span class="absolute w-0 h-0 hidden" />
</template>
