<script setup>
const { canAccessCurrentProduct: hasProductAccess } = useUserPermissions();

const { urlParams } = useAdminUrl();

const topbar = ref();
const isOpenMenu = ref(false);

function toggleMenu() {
    isOpenMenu.value = !isOpenMenu.value;
}

const { smallerThanLg } = useViewBreakpoints();

const showMenu = computed(() => {
    return !smallerThanLg.value || isOpenMenu.value;
});

useClickOutside(topbar, () => {
    if (isOpenMenu.value && smallerThanLg.value) {
        toggleMenu();
    }
});
</script>

<template>
    <div
        id="topbar"
        ref="topbar"
        class="bg-white h-[60px] md:h-[70px] border-b border-slate-300 flex justify-between sticky top-0 z-30"
    >
        <TopbarProductSelect />

        <div class="relative flex">
            <div
                v-if="showMenu"
                class="absolute top-10 right-2 sm:top-0 sm:right-0 sm:h-full"
            >
                <div
                    class="bg-white p-4 shadow-lg rounded-lg border sm:border-none sm:shadow-none sm:rounded-none sm:bg-transparent sm:flex sm:items-center sm:gap-3 sm:px-4 sm:px-6 sm:relative h-full"
                >
                    <CommonButton
                        v-if="hasProductAccess"
                        class="items-center"
                        icon
                        outline
                        link-to="/members/dashboard"
                    >
                        <template #custom-icon>
                            <IconExternalLink />
                        </template>

                        <span v-text="$t('global.employee_view')" />
                    </CommonButton>

                    <TopbarNotifications />

                    <NuxtLink
                        v-if="hasProductAccess && $role('admin|manager')"
                        exact-active-class="sm:bg-slate-100 rounded text-slate-800"
                        :to="`/admin/${urlParams?.team}/${urlParams?.product}/account`"
                        class="flex items-center sm:justify-center text-slate-400 hover:text-slate-800 pb-3 sm:p-1.5"
                        title="Account"
                        @click="toggleMenu"
                    >
                        <IconUser class="w-[25px] h-5 mr-4 sm:mr-0" />
                        <span
                            class="sm:hidden font-bold text-sm text-slate-800"
                        >
                            {{ $t('global.app_topbar.account') }}
                        </span>
                    </NuxtLink>

                    <button
                        exact-active-class="sm:bg-slate-100 rounded text-slate-800"
                        title="Logout"
                        class="sm:w-9 sm:h-9 flex items-center sm:justify-center text-slate-400 hover:text-slate-800 sm:p-1.5"
                        @click.prevent="logoutUser('/admin/login')"
                    >
                        <IconLogout class="w-[25px] h-5 mr-4 sm:mr-0" />
                        <span
                            class="sm:hidden font-bold text-sm text-slate-800"
                        >
                            {{ $t('global.app_topbar.logout') }}
                        </span>
                    </button>
                </div>
            </div>

            <div v-if="smallerThanLg" class="h-full flex">
                <button type="button" @click="toggleMenu">
                    <IconDots class="w-6 rotate-90" />
                </button>
            </div>
        </div>
    </div>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.4s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>
