<script setup>
const show = ref(false);

const { $apiFetch } = useNuxtApp();

const error = ref(null);

const loading = ref(false);

const slots = useSlots();
const emit = defineEmits(['close']);

const content = ref({});
const actions = ref([]);

const dialogName = ref();

const dialogType = ref();

const type = computed(() => {
    if (dialogType.value === 'warning' || dialogType.value == 2) {
        return 'warning';
    }

    if (dialogType.value === 'error' || dialogType.value == 3) {
        return 'error';
    }

    if (dialogType.value === 'success' || dialogType.value == 4) {
        return 'success';
    }

    return 'info';
});

const hasContent = computed(() => {
    return !!content.value.headline || !!content.value.message;
});

const hasHeadline = computed(() => {
    return !!content.value.headline && !slots.headline;
});

const hasActions = computed(() => {
    return actions.value?.length > 0;
});

const open = (event) => {
    error.value = null;
    // set required values
    dialogType.value = event.type;
    content.value = {
        headline: event.headline,
        message: event.message,
    };
    actions.value = event.actions || [];
    dialogName.value = event.dialog;

    // show dialog
    show.value = true;
};

const close = () => {
    // reset values
    show.value = false;
    content.value = {};
    actions.value = [];
    dialogName.value = null;
    error.value = null;

    // emit close event
    emit('close', content.value);
};

defineExpose({
    open,
    close,
});

const { headers, refreshHeaders } = useFetchHeaders({
    Accept: 'application/json',
    'Content-Type': 'application/json',
});

const performAction = async (key) => {
    if (!key || !dialogName.value) {
        return;
    }

    loading.value = true;
    refreshHeaders();

    let response = null;
    try {
        response = await $apiFetch(`/api/v1/notifications`, {
            method: 'POST',
            headers: headers.value,
            body: JSON.stringify({
                key,
                dialog: dialogName.value,
            }),
        });
    } catch (error) {
        if (error.response) {
            response = error.response;
        } else {
            error.value = error.message;
        }
    }
    loading.value = false;

    if (response.status !== 200) {
        error.value = response._data.message;
        return;
    }
    close();
};
</script>

<template>
    <CommonModal
        v-model:show="show"
        size="small"
        :type="type"
        :closable="!hasActions"
        @close="close"
    >
        <template v-if="hasHeadline" #header-container>
            <div class="flex gap-2 p-4">
                <div class="flex-shrink-0">
                    <IconCheckCircle
                        v-if="type === 'success'"
                        class="h-6 w-6 text-theme-success"
                    />
                    <IconError
                        v-if="type === 'error'"
                        class="h-6 w-6 text-theme-danger"
                    />
                    <IconInfo
                        v-if="type === 'info'"
                        class="h-6 w-6 text-theme-blue"
                    />
                    <IconExclamation
                        v-if="type === 'warning'"
                        class="h-6 w-6 text-theme-warning"
                    />
                </div>
                <h5
                    class="mb-0 text-lg font-semibold"
                    v-text="content.headline"
                />
            </div>
        </template>

        <template v-if="hasContent">
            <p>{{ content.message }}</p>

            <InputErrorMessage v-if="error" :error="error" />
        </template>

        <template #footer>
            <div v-if="hasActions" class="w-full flex justify-end gap-2">
                <template v-for="action in actions" :key="action.key">
                    <CommonButton
                        primary
                        :disabled="loading"
                        :loading="loading"
                        @click="performAction(action.key)"
                    >
                        {{ action.label }}
                    </CommonButton>
                </template>
            </div>
            <div v-else>
                <CommonButton primary @click="close">
                    {{ $t('global.close') }}
                </CommonButton>
            </div>
        </template>
    </CommonModal>
</template>
