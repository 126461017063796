<script setup>
const { externalLinks, hasLinks } = storeToRefs(useCurrentPlatformStore());

useHead({
    bodyAttrs: {
        class: 'font-sans antialiased bg-slate-100 text-slate-800',
        tagDuplicateStrategy: 'replace',
    },
});

const { data: auth } = useAuth();
</script>

<template>
    <div class="lg:flex">
        <AppHeader />

        <main class="relative flex-grow min-h-screen flex-col flex">
            <AppTopbar />
            <div class="flex-1">
                <NuxtPage />
            </div>

            <div v-if="hasLinks">
                <div
                    class="w-full justify-end flex-row py-5 pl-10 pr-20 bg-white text-slate-400 flex gap-4 border-t border-slate-200"
                >
                    <NuxtLink
                        v-if="externalLinks.siteNoticeUrl"
                        class="hover:text-slate-600"
                        target="_blank"
                        :to="externalLinks.siteNoticeUrl"
                    >
                        {{ $t('global.site_notice') }}
                    </NuxtLink>
                    <NuxtLink
                        v-if="externalLinks.privacyUrl"
                        class="hover:text-slate-600"
                        target="_blank"
                        :to="externalLinks.privacyUrl"
                    >
                        {{ $t('global.privacy') }}
                    </NuxtLink>
                    <NuxtLink
                        v-if="externalLinks.termsUrl"
                        class="hover:text-slate-600"
                        target="_blank"
                        :to="externalLinks.termsUrl"
                    >
                        {{ $t('global.terms') }}
                    </NuxtLink>
                </div>
            </div>
        </main>

        <HelpspaceWidget v-if="auth" />

        <NotificationOverlayTarget v-if="auth" />
        <NotificationDialogOverlayTarget v-if="auth" />
    </div>
</template>
