<script setup>
const storeTeams = useTeamsStore();

const { data: profile } = useAuth();

const { urlPath } = useAdminUrl();
const { teams, currentTeam, isCurrentTeamMain } = storeToRefs(storeTeams);

const { canAccessCurrentProduct: hasProductAccess } = useUserPermissions();

const { smallerThanLg } = useViewBreakpoints();

// TODO check if we can make this even more complicated
const toggleNavbar = () => {
    isOpen.value = !isOpen.value;
};

const backendMenuCollapsed = ref(profile.value?.backend_menu_collapse ?? false);

const isOpen = computed({
    get: () => {
        return !backendMenuCollapsed.value;
    },
    set: (value) => {
        backendMenuCollapsed.value = !value;
        // const body = document.body;
        // body.classList.toggle('nav-open', !value);

        saveNavbarState();
    },
});
const collapsed = computed(() => {
    return !isOpen.value;
});
const { execute: saveNavbarState } = useApiFetch(
    '/api/v1/toggle-backend-menu',
    '',
    {
        body: {
            state: collapsed,
        },
        method: 'PATCH',
        immediate: false,
        watch: false,
    }
);

await storeTeams.loadTeams();

const manageTeamModal = ref();

function closeMenu() {
    if (smallerThanLg.value) {
        toggleNavbar();
    }
}
</script>

<template>
    <header id="header" ref="header" :class="{ open: isOpen }">
        <nav class="flex flex-col justify-between h-full overflow-hidden">
            <div class="flex flex-col items-center">
                <div v-if="profile?.platform?.logo" class="px-8 pb-8">
                    <NuxtLink :to="`${urlPath}/dashboard`" @click="closeMenu">
                        <img
                            :src="profile?.platform?.logo?.src"
                            :alt="profile?.platform?.name"
                            class="max-h-20 object-contain"
                        />
                    </NuxtLink>
                </div>
                <NavNavbar :is-open="isOpen" @click:nav-item="closeMenu" />
            </div>

            <div v-if="hasProductAccess && $role('admin|manager')" class="px-8">
                <div v-if="isOpen">
                    <span
                        class="uppercase text-slate-400 text-xs font-medium tracking-wider block mb-2"
                    >
                        {{ $t('admin.location.administration') }}
                    </span>
                    <button
                        class="flex flex-nowrap items-center gap-3 w-full"
                        @click.prevent="manageTeamModal.open()"
                    >
                        <IconHome v-if="isCurrentTeamMain" class="text-white" />
                        <IconCompany v-else />
                        <span
                            v-if="isOpen"
                            class="text-white whitespace-nowrap text-sm font-medium"
                        >
                            {{ currentTeam.display_name }}
                        </span>
                    </button>
                </div>

                <button
                    v-if="!isOpen"
                    class="flex flex-nowrap items-center gap-3 w-full justify-center"
                    @click.prevent="manageTeamModal.open()"
                >
                    <IconHome v-if="isCurrentTeamMain" class="text-white" />
                    <IconCompany v-else />
                </button>
            </div>
        </nav>
        <button
            class="absolute top-1/2 -right-8 lg:-right-4 w-8 h-14 flex items-center justify-center bg-slate-800 hover:bg-gray-800 transition-colors rounded-r lg:rounded-l shadow-lg z-10"
            @click.prevent="toggleNavbar"
        >
            <IconNavbarArrow :is-open="isOpen" />
        </button>
        <button
            class="absolute right-8 top-6 z-20 w-8 h-8 flex items-center justify-center lg:hidden"
            @click.prevent="toggleNavbar"
        >
            <IconClose />
        </button>
    </header>

    <template v-if="$role('admin|manager')">
        <ModalManageLocations
            ref="manageTeamModal"
            :current-team="currentTeam"
            :teams="teams"
        />
    </template>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.2s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>
