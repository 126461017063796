<script setup>
import Dialog from './Dialog.vue';

const { data: profile } = useAuth();

const { $echo } = useNuxtApp();

const dialog = ref();
if (!$echo.inactive) {
    $echo
        .private(`customer-notifications.dialog.${profile.value.uuid}`)
        .listen('.new', (event) => {
            dialog.value?.open(event);
        });
}
</script>

<template>
    <div
        id="notification-overlay-target"
        aria-live="polite"
        role="status"
        class="fixed z-[100000] inset-0 flex items-end pointer-events-none px-4 py-24 sm:!items-start"
    >
        <div class="w-full flex flex-col items-center space-y-4 sm:!items-end">
            <!-- Dialog will be appended here -->
            <Dialog ref="dialog" />
        </div>
    </div>
</template>
